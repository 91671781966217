import Link from 'next/link';
import * as React from 'react';
import BaseLayout from '@/components/BaseLayout/BaseLayout';
import { ButtonPrimary } from '@/components/Button/ButtonPrimary.component';
import { NextPageWithLayout } from '@/pages/_app';
import { Routes } from '@/types/types';

export const NotFoundPage: NextPageWithLayout = () => {
  return (
    <BaseLayout
      currentStep="none"
      childrenBox={
        <div>
          <div className="max-w-[671px] px-5 flex flex-col justify-center items-center mx-auto mt-6 gap-8 overflow-hidden">
            <img
              src="/images/animated/christmas-tree-car.gif"
              alt=""
              className="object-cover w-[777px] h-[388px] -mt-[50px] -mb-[70px] md:object-none md:-my-0"
            />
            <h1 className="text-xl md:text-3xl font-bold">This page was not found!</h1>
            <Link href={Routes.landing_page}>
              <ButtonPrimary className="max-w-[200px]">
                <span className="text-[20px] text-white leading-4 font-bold">Go to Home</span>
              </ButtonPrimary>
            </Link>
          </div>
        </div>
      }
    />
  );
};

export default NotFoundPage;
